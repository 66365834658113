import React from "react";
import AuthView from '../components/AuthView';
import SecurityProvider from "../components/security-provider";

const AuthPage = (props) => {
    const businessAppConfiguration =
      props.pageContext.businessAppConfiguration || {};
    const { useRecaptchaInSignUp } = businessAppConfiguration;
    const { recaptchaKey } = businessAppConfiguration;
    const loadWithSecurityProvider = recaptchaKey && useRecaptchaInSignUp;
    return loadWithSecurityProvider ? (
      <SecurityProvider
        useReacptcha={useRecaptchaInSignUp}
        recaptchaKey={recaptchaKey}
      >
        <AuthView {...props} />
      </SecurityProvider>
    ) : (
        <AuthView {...props} />
    );
  };

export default AuthPage;
